
import Vue, { defineComponent } from 'vue';
import Root from '../components/Main.vue';
import { onboardingStore } from '@/state/onboardingStore';
import { IRoseClientInfoForOnboarding } from '../../../types';
import { parseInfoString } from '@/services/parseInfoString';
import { profileApi, r4cServerApi } from '@/services/data';
import { EditR4cRzTask } from '@/state/tasks';

export default defineComponent({
  components: {
    Root,
  },
  data: () => ({ initialized: false }),
  async mounted() {
    let info: IRoseClientInfoForOnboarding;
    try {
      info = parseInfoString<IRoseClientInfoForOnboarding>(this.$route.query.info);
    } catch (e) {
      console.error('error while parsing query info:', e.message);
      alert('Der verwendet Link enthält Fehler: ' + e.message);
      return;
    }

    try {
      await onboardingStore.dispatch.createOnboardingAuthSession(info);

      // load profile only for r4chost
      let profile = info.isApikey
        ? await r4cServerApi.r4cApps.loadClientProfile(info.cid)
        : await profileApi.loadClientProfile(info.cid);
      console.log('loaded profile', profile);
      onboardingStore.commit.setRoseProfile(profile);
      if (info.r4cSecret) {
        onboardingStore.commit.setR4cSecret(info.r4cSecret);
      }

      onboardingStore.commit.initializeAppTask(EditR4cRzTask);

      this.initialized = true;
    } catch (e) {
      console.error('error while parsing query info:', e.message);
      alert('Der Server konnte nicht erreicht werden: ' + e.message);
    }
  },
});
